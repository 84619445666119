import React from 'react';
import { Badge, Col, Container } from 'react-bootstrap';
import moment from 'moment';
interface ICommittedProjectionTable {
  projections: any[];
}

export const CommittedProjectionTable: React.FC<ICommittedProjectionTable> = ({ projections }) => {
  if (!projections) return null;

  return (
    <div className="row mb-3">
      <div className="col">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Forecast</th>
                <th>Name</th>
                <th>Changes</th>
                <th>Created by</th>
                <th>Committed at</th>
              </tr>
            </thead>
            <tbody>
              {projections?.map((proj: any, index) => {
                const brandChangesNumber = proj.forecastBudgetProjections?.filter?.((item: any) => {
                  return item?.forecastBudget?.budgetType === 'brand' && item.sourceChangeTypeId === 1;
                }).length;
                const brandFamilyChangesNumber = proj.forecastBudgetProjections?.filter?.(
                  (item: any) => item?.forecastBudget?.budgetType === 'brandFamily' && item.sourceChangeTypeId === 1,
                ).length;
                const productChangesNumber = proj.forecastProductProjections?.filter?.(
                  (item: any) => item.sourceChangeTypeId === 1,
                ).length;
                const calendarDate =
                  brandChangesNumber > 0
                    ? new Date(proj.forecastBudgetProjections[0]?.forecastBudget.forecastCalendar.startDate)
                    : new Date(proj.forecastProductProjections[0]?.forecastProduct.forecastCalendar.startDate);
                const calendarId =
                  brandChangesNumber > 0
                    ? proj.forecastBudgetProjections[0]?.forecastBudget.forecastCalendarId
                    : proj.forecastProductProjections[0]?.forecastProduct.forecastCalendarId;
                const territoryId =
                  brandChangesNumber > 0
                    ? proj.forecastBudgetProjections[0]?.forecastBudget.territoryId
                    : proj.forecastProductProjections[0]?.forecastProduct.territoryId;
                const calendarYear = calendarDate.getUTCFullYear();
                return (
                  <tr key={index}>
                    <td>
                      <a
                        href={
                          '/forecast/brand?forecastId=' +
                          proj.forecastId +
                          '&projectionId=' +
                          proj.projectionId +
                          '&forecastCalendarId=' +
                          calendarId +
                          '&territoryId=' +
                          territoryId +
                          '&year=' +
                          calendarYear +
                          '&userId=' +
                          proj.userId
                        }
                      >
                        {proj.forecast.name}
                      </a>
                    </td>
                    <td>
                      <a
                        href={
                          '/forecast/projection/' +
                          proj.projectionId +
                          '?forecastId=' +
                          proj.forecastId +
                          '&forecastCalendarId=' +
                          calendarId +
                          '&territoryId=' +
                          territoryId +
                          '&year=' +
                          calendarYear +
                          '&userId=' +
                          proj.userId
                        }
                      >
                        {proj.notes && proj.notes.trim().length > 0
                          ? proj.notes
                          : proj.user.firstName + '-' + proj.createdAt}
                      </a>
                    </td>
                    <td>
                      {brandChangesNumber !== 0 && (
                        <h6>
                          <Badge pill bg="primary" className="me-1">
                            {brandChangesNumber}
                          </Badge>
                          Brand {brandChangesNumber > 1 ? 'Changes' : 'Change'}
                        </h6>
                      )}
                      {brandFamilyChangesNumber !== 0 && (
                        <h6>
                          <Badge pill bg="primary" className="me-1">
                            {brandFamilyChangesNumber}
                          </Badge>
                          Brand Family {brandFamilyChangesNumber > 1 ? 'Changes' : 'Change'}
                        </h6>
                      )}
                      {productChangesNumber !== 0 && (
                        <h6>
                          <Badge pill bg="primary" className="me-1">
                            {productChangesNumber}
                          </Badge>
                          Product {productChangesNumber > 1 ? 'Changes' : 'Change'}
                        </h6>
                      )}
                    </td>
                    <td>
                      {proj.user.firstName} {proj.user.lastName}
                    </td>
                    <td>{moment(proj.updatedAt).format('MM-DD-YYY hh:mm A')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CommittedProjectionTable;
