import React, { useEffect, useState } from 'react';
import { Badge, Col, Container } from 'react-bootstrap';
import ProjectionService from '../../services/projection.service';
import { useForecast } from '../../providers/ForecastProvider';
import BarChart from './BarChart';
import { hideLoading, showLoading } from '../../lib/uiService';
import { softRgbaColors } from '../../constants';
import { calculateLinearRegression, darkenRgbaColor } from '../../utils/calculation';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import useForecastModal from '../../hooks/useForecastModal';
import ForecastService from '../../services/forecast.service';
import { TreePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { buildTreeOptionsFromTerritories } from '../../utils/buildTreeOption';
import { formatNum } from '../../utils/formatNum';
import OpenProjectionTable from './OpenProjectionTable';
import CommittedProjectionTable from './CommittedProjectionTable';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Dashboard = () => {
  const { forecastId, currentForecastYear } = useForecast();
  const { showForecastModal, setShowForecastModal } = useForecastModal();
  const [projections, setProjections] = useState([]);
  const [committedProjections, $committedProjections] = useState([]);
  const [userTerritories, setUserTerritories] = useState([]);
  const [caseGoal, setCaseGoal] = useState(0);
  const [revenueGoal, setRevenueGoal] = useState(0);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState('');
  const [dataWasCalled] = useState(false);
  const [revenueData, setRevenueData] = useState<any>([]);
  const [quantityData, setQuantityData] = useState<any>([]);
  const getData = async () => {
    if (forecastId && currentForecastYear && selectedTerritoryId) {
      showLoading();
      await ProjectionService.GetProjections({
        forecastId,
        currentForecastYear,
        territoryId: selectedTerritoryId,
      })
        .then(({ data }) => {
          const currentDate = new Date();
          setProjections(data.projections);
          $committedProjections(data.committedProjections);
          const summedForecastCases = data.graphData.dividedQuantities.reduce(
            (accumulator: number, currentValue: number) => accumulator + currentValue,
            0,
          );
          const summedCurrentCases = data.graphData.forecastYearSalesCases.reduce(
            (accumulator: number, currentValue: number) => accumulator + currentValue,
            0,
          );
          const summedForecastRevenue = data.graphData.dividedRevenues.reduce(
            (accumulator: number, currentValue: number) => accumulator + currentValue,
            0,
          );
          const summedCurrentRevenue = data.graphData.forecastYearSalesRevenue.reduce(
            (accumulator: number, currentValue: number) => accumulator + currentValue,
            0,
          );
          const casesGoal = (summedCurrentCases / summedForecastCases) * 100 || 0;
          const revenuesGoal = (summedCurrentRevenue / summedForecastRevenue) * 100 || 0;
          setCaseGoal(casesGoal === Infinity ? 100 : casesGoal);
          setRevenueGoal(revenuesGoal === Infinity ? 100 : revenuesGoal);
          setRevenueData([
            {
              label: `Trendline Forecasted Revenue of ${currentForecastYear}`,
              data: calculateLinearRegression(data.graphData.dividedRevenues),
              backgroundColor: darkenRgbaColor(softRgbaColors[4], 30),
              borderColor: darkenRgbaColor(softRgbaColors[4], 30),
              borderWidth: 2,
              type: 'line',
            },
            {
              label: `Trendline Revenue of ${currentDate.getFullYear()}`,
              data: calculateLinearRegression(data.graphData.currentSalesRevenue),
              backgroundColor: darkenRgbaColor(softRgbaColors[3], 30),
              borderColor: darkenRgbaColor(softRgbaColors[3], 30),
              borderWidth: 2,
              type: 'line',
            },
            {
              label: `Revenue of ${currentForecastYear - 1}`,
              data: data.graphData.lastYearSalesRevenue,
              backgroundColor: softRgbaColors[2],
              borderColor: softRgbaColors[2],
              borderWidth: 1,
              pointStyle: false,
            },
            {
              label: `Revenue of ${currentForecastYear}`,
              data: data.graphData.forecastYearSalesRevenue,
              backgroundColor: softRgbaColors[1],
              borderColor: softRgbaColors[1],
              borderWidth: 1,
              pointStyle: false,
            },
            {
              label: `Forecasted Revenue of ${currentForecastYear}`,
              data: data.graphData.dividedRevenues,
              backgroundColor: softRgbaColors[0],
              borderColor: softRgbaColors[0],
              borderWidth: 1,
              pointStyle: false,
            },
          ]);
          setQuantityData([
            {
              label: `Trendline Cases of ${currentDate.getFullYear()}`,
              data: calculateLinearRegression(data.graphData.currentSalesCases),
              backgroundColor: darkenRgbaColor(softRgbaColors[3], 30),
              borderColor: darkenRgbaColor(softRgbaColors[3], 30),
              borderWidth: 2,
              type: 'line',
            },
            {
              label: `Trendline Forecasted Cases of ${currentForecastYear}`,
              data: calculateLinearRegression(data.graphData.dividedQuantities),
              backgroundColor: darkenRgbaColor(softRgbaColors[4], 30),
              borderColor: darkenRgbaColor(softRgbaColors[4], 30),
              borderWidth: 2,
              type: 'line',
            },
            {
              label: `Cases of ${currentForecastYear - 1}`,
              data: data.graphData.lastYearSalesCases,
              backgroundColor: softRgbaColors[2],
              borderColor: softRgbaColors[2],
              borderWidth: 1,
              pointStyle: false,
            },
            {
              label: `Cases of ${currentForecastYear}`,
              data: data.graphData.forecastYearSalesCases,
              backgroundColor: softRgbaColors[1],
              borderColor: softRgbaColors[1],
              borderWidth: 1,
              pointStyle: false,
            },
            {
              label: `Forecasted Cases Sold of ${currentForecastYear}`,
              data: data.graphData.dividedQuantities,
              backgroundColor: softRgbaColors[0],
              borderColor: softRgbaColors[0],
              borderWidth: 1,
              pointStyle: false,
            },
          ]);
          hideLoading();
        })
        .catch((err) => {
          console.log(err);
          hideLoading();
        });
    }
  };

  const getUserTerritories = async () => {
    await ForecastService.GetUserTerritories()
      .then(({ data }) => {
        setUserTerritories(buildTreeOptionsFromTerritories(data.territories));
        setSelectedTerritoryId(data.highestTerritory.territoryId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserTerritories();
  }, []);

  useEffect(() => {
    getData();

    return () => {
      setQuantityData([]);
      setRevenueData([]);
    };
  }, [forecastId, currentForecastYear, selectedTerritoryId]);

  useEffect(() => {
    if (!dataWasCalled) {
      getData();
    }
  }, [dataWasCalled]);

  return (
    <React.Fragment>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <Container fluid className="p-0">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Dashboard</h3>
        </Col>
        <div className="row">
          <div className="col">
            <div className="card shadow p-0 mb-40">
              <div className="card-header py-1">
                <p className="text-primary m-0 fw-bold">Projections</p>
              </div>
              <div className="card-body">
                <Tabs defaultActiveKey="open_projections" className="mb-3">
                  <Tab eventKey="open_projections" title="Open Projections">
                    <OpenProjectionTable projections={projections} />
                  </Tab>
                  <Tab eventKey="committed_projections" title="Committed Projections">
                    <CommittedProjectionTable projections={committedProjections} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow" style={{ marginBottom: '44px' }}>
          <div className="card-header py-3">
            <div className="row">
              <div className="col-md-6 text-nowrap">
                <div className="dataTables_length" aria-controls="dataTable">
                  <p className="text-primary m-0 fw-bold">% Goal Progress</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
                  <TreePicker
                    defaultExpandAll
                    value={selectedTerritoryId}
                    data={userTerritories}
                    style={{ width: 246 }}
                    onChange={(newTerritoryId: any) => {
                      setSelectedTerritoryId(newTerritoryId);
                    }}
                    placeholder={'Select Territory'}
                    menuStyle={{ zIndex: 5000, height: '240px' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <p className="text-center font-weight-bold">{formatNum(caseGoal, 0, 0)}% to Cases Goal</p>
                <BarChart data={quantityData} type="cases" />
              </div>
              <div className="col">
                <p className="text-center font-weight-bold">{formatNum(revenueGoal, 0, 0)}% to Revenue Goal</p>
                <BarChart data={revenueData} type="revenue" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
