import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import { ITerritoryOption } from '../../lib/types';

interface ITerritoryFilter {
  placeholder: string;
  setSavedValues?: (x: any) => void;
  isModal?: boolean | undefined;
  isParamData?: boolean;
  filterOptions?: any;
}

const TerritoryFilter: React.FC<ITerritoryFilter> = (props) => {
  const { placeholder, setSavedValues, isModal, isParamData, filterOptions } = props;
  const [selectedItem, setSelectedItem] = useState<string>('');
  const { setTerritoryId, setForecastUpdated, hasForecasts, territoryId } = useForecast();
  const [territories, setTerritories] = useState<ITerritoryOption[]>([]);
  const [allTerritories, $allTerritories] = useState<ITerritoryOption[]>([]);

  useEffect(() => {
    ForecastService.GetTerritories()
      .then(({ data }) => {
        $allTerritories(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    let value = [];
    if (filterOptions) {
      value = allTerritories.filter((item: any) => {
        if (filterOptions[item.territoryId]) {
          item.value = filterOptions[item.territoryId];
          item.label = item.name;
          return true;
        }
        return false;
      });
    } else {
      value = allTerritories;
    }
    setTerritories(value);
  }, [filterOptions, allTerritories]);

  useEffect(() => {
    const prevValue = localStorage.getItem('Territory');
    if (!territoryId) {
      if (prevValue && !isParamData) {
        setSelectedItem(prevValue);
        setTerritoryId(prevValue);
        if (isModal && setSavedValues) {
          setSavedValues((prevState: any) => {
            return { ...prevState, territoryId: prevValue };
          });
        }
      }
    } else {
      if (isParamData) {
        setSelectedItem(prevValue);
      } else {
        setSelectedItem(territoryId);
      }
      if (isModal && setSavedValues) {
        setSavedValues((prevState: any) => {
          return { ...prevState, territoryId: territoryId };
        });
      }
    }
  }, [territoryId, isParamData]);

  const handleChange = (newTerritoryId: any) => {
    if (!newTerritoryId) {
      return;
    } else {
      if (isModal && setSavedValues) {
        setSelectedItem(newTerritoryId);
        localStorage.setItem('Territory', newTerritoryId);
        localStorage.setItem('previousTerritory', newTerritoryId);
        setSavedValues((prevState: any) => {
          return { ...prevState, territoryId: newTerritoryId };
        });
      } else {
        setSelectedItem(newTerritoryId);
        setTerritoryId(newTerritoryId, true);
        setForecastUpdated(true);
      }
    }
  };

  const handleClear = () => {
    const nationalTerritory = allTerritories.find((item: any) => !item.parentId);
    if (nationalTerritory) {
      const newTerritoryId = nationalTerritory.territoryId;
      handleChange(newTerritoryId);
    }
  };

  return (
    <div>
      <div className="mb-2">{placeholder}</div>
      <div className="d-grid gap-1">
        {territories.map((item) => {
          const { label, value, territoryId } = item;
          const isSelected = selectedItem === territoryId;
          const variant = isSelected ? 'info' : 'light';
          const bg = isSelected ? 'info' : 'secondary';
          return (
            <Button variant={variant} size="sm" key={territoryId} onClick={() => handleChange(territoryId)}>
              {label}
              {`   `}
              <Badge bg={bg}>{value}</Badge>
            </Button>
          );
        })}
        {territories?.length > 0 && (
          <Button variant={'light'} size="sm" onClick={() => handleClear()} className="btn-clear">
            <span className="btn-close mx-1"></span>
            Clear
          </Button>
        )}
      </div>
    </div>
  );
};

export default TerritoryFilter;
