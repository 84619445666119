import { AxiosResponse } from 'axios';
import Axios from '../lib/axios';
import {
  CreateForecastRequest,
  GetForecastReportRequest,
  GetUsersRequest,
  UpdateForecastRequest,
  IUserData,
  ISalesFilterType,
  ITerritory,
  ImportType,
} from '../lib/types';

const CreateForecast = ({ data, asyncForecast }: { data: CreateForecastRequest; asyncForecast: boolean }) => {
  if (asyncForecast) {
    return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast/queue', data);
  } else {
    return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast', data);
  }
};

const UpdateForecast = (updateForecastRequest: UpdateForecastRequest) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast', updateForecastRequest);
};

const DeleteForecast = (forecastId: string, hardDelete = false) => {
  return Axios.delete(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast/' + forecastId, { params: hardDelete });
};

const DuplicateForecast = (forecastId: string, forecastName: string) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast/duplicate', {
    forecastId,
    name: forecastName,
  });
};

const RecalcualteForecast = (forecastId: string) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast/recalculate', {
    forecastId,
  });
};
const GetFullForecastReport = (getForecastReportRequest: GetForecastReportRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast/report/full', {
    responseType: 'blob', // Ensure response is treated as a blob
    params: getForecastReportRequest,
  });
};

const GetGreatvinesExport = (getForecastReportRequest: GetForecastReportRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/forecast/export/greatvines', {
    responseType: 'blob', // Ensure response is treated as a blob
    params: getForecastReportRequest,
  });
};
// User API

const GetUsers = (getUsersRequest: GetUsersRequest) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/user', {
    params: getUsersRequest,
  });
};

const GetUser = (userId: string) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/user/' + userId);
};

const CreatUser = (createUserRequest: IUserData) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/user', createUserRequest);
};

const EditUser = (userId: any, editUserRequest: IUserData) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/user/' + userId, editUserRequest);
};

const DeleteUser = (userId: string) => {
  return Axios.delete(process.env.REACT_APP_SERVER_ROOT + '/admin/user/' + userId);
};

const ValidaterUser = (name: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/user/check', { name });
};

// territory API
const GetTerritoryData = (territoryId: string) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/territory', { territoryId });
};

const UpdateTerritoryData = (territory: ITerritory) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/territory', { territory });
};

const CreateTerritory = (territory: ITerritory) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/create/territory', { territory });
};

// role API
const GetRoles = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/roles');
};

const GetBrand = (brandId: string) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/brand/' + brandId);
};

const CreateBrand = (name: string, isActive?: boolean) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/brand', { name, isActive });
};

const EditBrand = (brandId: string, name: string, isActive: boolean) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/brand/' + brandId, { name, isActive });
};

const DeleteBrand = (brandId: string) => {
  return Axios.delete(process.env.REACT_APP_SERVER_ROOT + '/admin/brand/' + brandId);
};

// approval API
const RequestProductApproval = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/approval', data);
};

const RequestApprovalForMultipleProducts = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/approve-many', data);
};

const AddApprovalAndCheckRequestState = (data: any) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/product/approval', data);
};

const RejectApproval = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/approval-reject', data);
};

// history API
const GetProductHistoryVersion = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/single-history', data);
};

const GetApprovalCount = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/history/count', data);
};

const GetProductHistory = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/history', data);
};

const UpdateProductHistory = (data: any) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/product/history', data);
};

// brand family API

const CreateBrandFamily = (brandId: string, name: any, isDefault: boolean) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/brand-family', { name, brandId, isDefault });
};

const EditBrandFamily = (brandFamilyId: string, name: any, isDefault: boolean) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/brand-family/' + brandFamilyId, { name, isDefault });
};

const GetBrandFamilysWithBrand = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/brands');
};

const GetWarehouses = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/warehouses');
};

const GetWarehouseFees = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/warehouse-fees');
};

// import/export API
const ImportSales = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/sales/import/xls', formData);
};
const ExportSales = (filterData: ISalesFilterType) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/sales/export/xls', {
    params: filterData,
    responseType: 'blob',
  });
};
const ImportPricing = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/import/product/price', formData);
};
const ExportPricing = (filterData: any) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/export/product/price', {
    params: filterData,
    responseType: 'blob',
  });
};
const ImportProduct = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/import/product', formData);
};
const ExportProduct = (filterData: any) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/export/product', {
    params: filterData,
    responseType: 'blob',
  });
};
const GetImportTemplate = (type: ImportType) => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/import/template', {
    params: { type },
    responseType: 'blob',
  });
};
const SeedSales = (values) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/import/parkstreet', values);
};

const ManualForecastExport = () => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/export/forecast', {});
};
// Products and Labels
const GetProducts = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/products');
};

const GetProductsAndLabels = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/list', formData);
};

const GetProductsFobFiles = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/products/fobs', {
    responseType: 'blob',
  });
};

const CreateProduct = (product: any, forecastPrices: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product', { product, forecastPrices });
};

const UpdateProduct = (product: any, forecastPrices: any, deletedTerritoryPrices: any) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/product', {
    product,
    forecastPrices,
    deletedTerritoryPrices,
  });
};

const GetProductPrices = (productId: string) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/product/price', { productId });
};

const GetLabelsWithBrand = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/labels');
};

const CreateLabel = (label: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/label', { label });
};

const UpdateLabel = (label: any) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/label', { label });
};

// Fees API
const GetAllFees = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/admin/fees');
};

const RequestFeesApproval = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/request-fees-change', data);
};

const GetFreightFees = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/freight-fee', data);
};

const GetAvailableWarehousePerFreight = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/warehouses-per-brand', data);
};

const UpdateFeeRequest = (data: any) => {
  return Axios.put(process.env.REACT_APP_SERVER_ROOT + '/admin/update-fees-request', data);
};

const AddFeeAproval = (data: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/admin/add-fee-request-approval', data);
};

const importServices: { [key: string]: (formData: any) => Promise<AxiosResponse<any, any>> } = {
  [ImportType.Sales]: ImportSales,
  [ImportType.Product]: ImportProduct,
  [ImportType.Pricing]: ImportPricing,
};

const exportServices: { [key: string]: (filter: any) => Promise<AxiosResponse<any, any>> } = {
  [ImportType.Sales]: ExportSales,
  [ImportType.Product]: ExportProduct,
  [ImportType.Pricing]: ExportPricing,
};

const AdminService = {
  CreateForecast,
  UpdateForecast,
  DeleteForecast,
  DuplicateForecast,
  RecalcualteForecast,
  GetFullForecastReport,
  GetGreatvinesExport,
  CreatUser,
  GetUsers,
  GetUser,
  EditUser,
  DeleteUser,
  ValidaterUser,
  GetRoles,
  RequestProductApproval,
  AddApprovalAndCheckRequestState,
  GetProductHistoryVersion,
  GetApprovalCount,
  RejectApproval,
  GetProductHistory,
  UpdateProductHistory,
  GetBrand,
  CreateBrand,
  EditBrand,
  DeleteBrand,
  CreateBrandFamily,
  EditBrandFamily,
  GetLabelsWithBrand,
  CreateLabel,
  UpdateLabel,
  GetProducts,
  CreateProduct,
  UpdateProduct,
  GetProductPrices,
  ImportSales,
  ExportSales,
  SeedSales,
  ManualForecastExport,
  GetTerritoryData,
  UpdateTerritoryData,
  CreateTerritory,
  GetImportTemplate,
  GetProductsAndLabels,
  GetProductsFobFiles,
  GetBrandFamilysWithBrand,
  GetWarehouses,
  GetWarehouseFees,
  importServices,
  exportServices,
  GetAllFees,
  RequestFeesApproval,
  UpdateFeeRequest,
  AddFeeAproval,
  GetFreightFees,
  GetAvailableWarehousePerFreight,
  RequestApprovalForMultipleProducts,
};

export default AdminService;
