import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BrandProjectionsTable from './BrandProjectionsTable';
import BrandFamilyProjectionsTable from './BrandFamilyProjectionsTable';
import ProductProjectionsTable from './ProductProjectionsTable';
import { ProjectionProvider, useProjection } from '../../providers/ProjectionProvider';
import useForecastModal from '../../hooks/useForecastModal';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import NotFound from '../../components/NotFound';
import { hideLoading } from '../../lib/uiService';
import { useForecast } from '../../providers/ForecastProvider';
import { ForecastStatusWatcher } from '../../hooks/useWatchForecastStatus';

import { ActiveProjectionWidget } from '../../components/projection/ActiveProjectionWidget';
import { useAuth } from '../../providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { UserRole } from '../../lib/types';

const useAutoConfigProjection = (projection) => {
  const { forecastCalendarId, setForecastCalendarId, forecastId, setForecastId, territoryId, setTerritoryId } =
    useForecast();

  useEffect(() => {
    if (!projection) return;

    const brandChanges = projection.getBrandChanges();
    const forecastIds = {};
    const territoryIds = {};
    const forecastCalendarIds = {};
    // auto detect forecastId, territoryId and calendarId
    brandChanges.map((change: any) => {
      const forecastId = change.forecastBudget?.forecastId;
      const territoryId = change.forecastBudget?.territoryId;
      const forecastCalendarId = change.forecastBudget?.forecastCalendarId;
      forecastIds[forecastId] = (forecastIds[forecastId] || 0) + 1;
      territoryIds[territoryId] = (territoryIds[territoryId] || 0) + 1;
      forecastCalendarIds[forecastCalendarId] = (forecastCalendarIds[forecastCalendarId] || 0) + 1;
    });
    const getMaxCount = (obj: any) => {
      let max = 0;
      let maxKey = '';
      for (const key in obj) {
        if (obj[key] > max) {
          max = obj[key];
          maxKey = key;
        }
      }
      return maxKey;
    };

    // auto update active forecastId
    if (!forecastIds[forecastId]) {
      const val = getMaxCount(forecastIds);
      setForecastId(val);
    }

    // auto update active forecastId
    if (!forecastCalendarIds[forecastCalendarId]) {
      const val = getMaxCount(forecastCalendarIds);
      setForecastCalendarId(val);
    }

    // auto update active forecastId
    if (!territoryIds[territoryId]) {
      const val = getMaxCount(territoryIds);
      setTerritoryId(val);
    }
  }, [projection, forecastCalendarId, territoryId, forecastId]);
};

const BrandForecastView: React.FC = () => {
  const { hasForecasts, forecastId, forecastCalendarId, territoryId } = useForecast();
  const { isCheckingLoggedState, role } = useAuth();
  const [projection, setProjection] = useState<any>(null);
  const { showForecastModal, setShowForecastModal } = useForecastModal();
  const location = useLocation();
  const navigate = useNavigate();
  const { id: projectionId } = useParams();
  const sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
    brands: useRef<HTMLDivElement>(null),
    brandFamilies: useRef<HTMLDivElement>(null),
    products: useRef<HTMLDivElement>(null),
  };
  const projectionContext = useProjection();

  useEffect(() => {
    if (isCheckingLoggedState) {
      hideLoading();
    }
  }, []);

  useEffect(() => {
    if (hasForecasts && forecastId) {
      fetchProjection(forecastId);
    }
  }, [forecastId]);

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      if (sectionRefs[sectionId] && sectionRefs[sectionId].current) {
        sectionRefs[sectionId].current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  const fetchProjection = async (forecastId: string) => {
    projectionContext.isLoading = true;
    try {
      await projectionContext.handlers.fetch(forecastId, {
        calendarId: forecastCalendarId,
        territoryId,
        checkUser: !role?.includes(UserRole.Admin),
        projectionId,
      });
      const projection = projectionContext.getProjection();
      if (!projection) {
        navigate('/forecast/brand');
      }
      setProjection(projection);
    } catch (error) {
      console.error(error);
    } finally {
      projectionContext.isLoading = false;
    }
  };

  // useAutoConfigProjection(projection);

  const blurredStyle = {
    filter: projectionContext.isLoading ? 'blur(5px)' : 'none',
    transition: 'filter 0s ease',
  };
  return (
    <>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <div className="row">
        <NotFound isNotFound={projection === undefined} title="Projection not found">
          <div className="col-12 col-md-12 col-lg-4">
            {projectionContext.isLoading && (
              <div
                className="position-absolute d-flex align-items-center justify-content-center"
                style={{ height: '300px', width: '340px' }}
              >
                <div
                  className="me-2 spinner-border text-info"
                  style={{
                    position: 'relative',
                    zIndex: 1,
                  }}
                ></div>
              </div>
            )}
            <div style={blurredStyle}>
              <ActiveProjectionWidget
                isSummary={true}
                linkProjection={false}
                header={
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="text-lg fw-bold">{projection ? 'Projection Details' : 'No Active Projection'}</p>
                    </div>
                    {projection && (
                      <div className="d-flex justify-content-between">
                        <div className="">
                          Projected by:{' '}
                          {[projection?.projection?.user?.firstName, projection?.projection?.user?.lastName]
                            .filter(Boolean)
                            .join(' ')}
                        </div>
                      </div>
                    )}
                  </>
                }
                projection={projection}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-8">
            {projectionContext.isLoading && (
              <div
                className="position-absolute d-flex align-items-center justify-content-center"
                style={{ height: '300px', width: '700px' }}
              >
                <div
                  className="me-2 spinner-border text-info"
                  style={{
                    position: 'relative',
                    zIndex: 1,
                  }}
                ></div>
              </div>
            )}
            <div style={blurredStyle} id="brands">
              <BrandProjectionsTable projection={projection} />
            </div>
            {projectionContext.isLoading && (
              <div
                className="position-absolute d-flex align-items-center justify-content-center"
                style={{ height: '300px', width: '700px' }}
              >
                <div
                  className="me-2 spinner-border text-info"
                  style={{
                    position: 'relative',
                    zIndex: 1,
                  }}
                ></div>
              </div>
            )}
            <div style={blurredStyle} id="brandFamilies">
              <BrandFamilyProjectionsTable projection={projection} />
            </div>
            {projectionContext.isLoading && (
              <div
                className="position-absolute d-flex align-items-center justify-content-center"
                style={{ height: '300px', width: '700px' }}
              >
                <div
                  className="me-2 spinner-border text-info"
                  style={{
                    position: 'relative',
                    zIndex: 1,
                  }}
                ></div>
              </div>
            )}
            <div style={blurredStyle} id="products">
              <ProductProjectionsTable projection={projection} />
            </div>
          </div>
        </NotFound>
      </div>
    </>
  );
};

const BrandForecast = () => {
  return (
    <ForecastStatusWatcher>
      <ProjectionProvider>
        <BrandForecastView />
      </ProjectionProvider>
    </ForecastStatusWatcher>
  );
};

export default BrandForecast;
