import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useTable, useSortBy, useExpanded, useGlobalFilter } from 'react-table';
import { Table } from 'react-bootstrap';
import { useForecast } from '../../providers/ForecastProvider';
import { useProjection } from '../../providers/ProjectionProvider';
import useForecastCalculator from '../../hooks/useForecastCalculator';
import { calculateRevenueGrowth, calculateSalesGrowth } from '../../utils/calculation';
import { hideLoading, showLoading } from '../../lib/uiService';
import TableHead from '../../components/common/TableHead';
import TableBody from '../../components/common/TableBody';
import { useBrandFamilyProjectionsTableColumns } from './BrandFamilyProjectionsTableHooks';
import { toast } from 'react-toastify';
import { UserRole } from '../../lib/types';
import { useAuth } from '../../providers/AuthProvider';

const BrandFamilyProjectionsTable: React.FC<any> = ({ projection }: { projection: any }) => {
  const { id: projectionId } = useParams();
  const { role } = useAuth();
  const { forecastId, territoryId, hasForecasts, forecastCalendarId, isForecastClosed } = useForecast();
  const [brandForecasts, setBrandForecasts] = useState<any[]>([]);
  const [initialData, setInitialData] = useState<any[]>([]);
  const projectionContext = useProjection();
  const baseQuantityRef = React.useRef<any>({});
  const [isDirty, setIsDirty] = useState(false);
  const [isAutoSave, setIsAutoSave] = useState(false);
  const { forecastCalculator, loadForecastCalculator } = useForecastCalculator();
  const ref = React.useRef<any>({});
  Object.assign(ref.current, { forecastCalculator });

  useEffect(() => {
    const localStorageCalendarId = localStorage.getItem('TimePeriod') || '';
    const calendarId =
      forecastCalendarId && forecastCalendarId !== 'undefined' ? forecastCalendarId : localStorageCalendarId;

    const currentTerritoryId =
      territoryId && territoryId !== 'undefined' ? territoryId : localStorage.getItem('Territory');
    if (hasForecasts && forecastId && calendarId && currentTerritoryId) {
      if (projection) {
        const changes = projection.getBrandFamilyChanges();
        const filteredBrandChanges = changes.filter((change: any) => {
          return (
            change.forecastBudget.territoryId === currentTerritoryId &&
            change.forecastBudget.forecastCalendarId === calendarId
          );
        });
        filteredBrandChanges.forEach((change: any) => {
          const { forecastBudgetId, quantity, salesGrowth } = change.forecastBudget;
          baseQuantityRef.current[forecastBudgetId] = (Number(quantity) * 100) / (Number(salesGrowth) + 100);
        });
        loadForecastCalculator(forecastId, calendarId, currentTerritoryId);
        setInitialData(filteredBrandChanges);
        setBrandForecasts(filteredBrandChanges);
      } else {
        setInitialData([]);
        setBrandForecasts([]);
      }
    }
  }, [forecastId, territoryId, forecastCalendarId, projection]);

  useEffect(() => {
    if (isAutoSave) handleSave();
  }, [brandForecasts]);

  const handleChange = (id: string, updatedData: any) => {
    setBrandForecasts((prevData) => {
      const prevItem = prevData.find((item: any) => item.forecastBudgetProjectionId === id);
      const { forecastCalculator } = ref.current;
      const baseQuantity = baseQuantityRef.current[prevItem.forecastBudgetId];
      if (updatedData.quantity !== undefined && baseQuantity && !prevItem.isZeroCase) {
        const salesGrowth = calculateSalesGrowth(updatedData.quantity, baseQuantity);
        updatedData.salesGrowth = salesGrowth;
      }
      if (Number(updatedData.salesGrowth) === -100) {
        return prevData;
      }

      forecastCalculator.forecastBudgetCollection.updateForecastBudgetGrowth({
        ...prevItem.forecastBudget,
        ...prevItem,
        ...updatedData,
      });
      const updatedItems = forecastCalculator.getForecastBudgets();
      return prevData.map((row) => {
        if (row.forecastBudgetProjectionId === id) {
          const updatedItem = updatedItems.find((item: any) => item.forecastBudgetId === prevItem.forecastBudgetId);
          const newRow = {
            ...row,
            ...updatedData,
          };
          const newForecastRevenue = updatedItem.revenue;
          const newRevenueGrowth = calculateRevenueGrowth(newRow['salesGrowth'], newRow['priceGrowth']);
          const newForecastQuantity = updatedItem.quantity;
          return {
            ...newRow,
            quantity: newForecastQuantity,
            revenue: newForecastRevenue,
            revenueGrowth: newRevenueGrowth,
          };
        } else {
          return row;
        }
      });
    });
    setIsDirty(true);
  };

  const handleRemove = (id: string) => {
    setBrandForecasts((prevData) => {
      return prevData.filter((row) => row.forecastBudgetProjectionId !== id);
    });
    setIsAutoSave(true);
  };

  const handleSave = () => {
    showLoading();
    const changedRows = brandForecasts.filter((brandForecast, index) => {
      return (
        brandForecast.salesGrowth !== initialData[index].salesGrowth ||
        brandForecast.priceGrowth !== initialData[index].priceGrowth
      );
    });
    if (changedRows.length) {
      const data = changedRows.map(({ forecastBudgetId, salesGrowth, priceGrowth }) => ({
        forecastBudgetId,
        salesGrowth,
        priceGrowth,
      }));

      projectionContext.handlers
        .update({
          territoryId,
          projectionId,
          isExec: false,
          data,
        })
        .then(() => {
          hideLoading();
          setInitialData(brandForecasts);
          toast.success('Successfully Updated Brand Family Projection');
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
    // find removed rows
    const removedRows = initialData.filter((initialRow) => {
      return !brandForecasts.find((row) => row.forecastBudgetProjectionId === initialRow.forecastBudgetProjectionId);
    });
    if (removedRows.length) {
      const data = removedRows.map(({ forecastBudgetProjectionId }) => ({
        forecastBudgetProjectionId,
      }));
      projectionContext.handlers
        .discardLine({
          territoryId,
          projectionId,
          data,
        })
        .then(() => {
          projectionContext.handlers.fetch(forecastId, {
            calendarId: forecastCalendarId,
            territoryId,
            checkUser: !role?.includes(UserRole.Admin),
          });
          hideLoading();
          setInitialData(brandForecasts);
          toast.success('Successfully discarded Brand Family Projection');
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
    setIsDirty(false);
    setIsAutoSave(false);
  };

  const handleDiscardAll = () => {
    brandForecasts.forEach((row) => {
      handleRemove(row.forecastBudgetProjectionId);
    });
    setIsAutoSave(true);
  };

  const handleSaveItem = React.useCallback(
    (changedData?: any) => {
      const changedRows = changedData;

      if (changedRows.length === 0) return;
      const data = changedRows.map(({ forecastBudgetId, salesGrowth, priceGrowth, explanation, quantity }: any) => ({
        forecastBudgetId,
        salesGrowth,
        priceGrowth,
        explanation,
        quantity,
      }));
      showLoading();
      projectionContext.handlers
        .update({
          territoryId,
          forecastId,
          isExec: false,
          data,
        })
        .then(() => {
          hideLoading();
          setInitialData(brandForecasts);
          toast.success('Successfully Updated Brand Family Projection');
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
      hideLoading();
    },
    [brandForecasts],
  );
  const isEditable = projection?.projection?.isCommitted !== true;

  const columns = useBrandFamilyProjectionsTableColumns({
    handleChange,
    handleRemove,
    handleSaveItem,
    isForecastClosed,
    isEditable,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: useMemo(() => brandForecasts, [brandForecasts]),
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  return (
    <div className="card shadow" style={{ marginBottom: '44px' }}>
      <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">Brand Family Projections</p>
      </div>
      <div className="card-body">
        <div>
          <Table striped className="table-sm" bordered {...getTableProps()} responsive>
            <TableHead headerGroups={headerGroups} />
            <TableBody
              {...getTableBodyProps()}
              rows={rows}
              renderRow={(row: any, key: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={key} className={`table-body-font-size`}>
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`${key}-${index}`}
                          style={{
                            border: 'none',
                            backgroundColor: '#fff',
                          }}
                          className={'text-dark align-middle'}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              }}
            />
            <tfoot>
              <tr>
                {columns.map((column: any, index: number) => {
                  return (
                    <td key={index} className="cursor-pointer">
                      {column?.Footer?.({ data: brandForecasts })}
                    </td>
                  );
                })}
              </tr>
            </tfoot>
          </Table>
        </div>
        {isEditable && (
          <div className="row">
            <div className="col-6">
              <button
                disabled={brandForecasts.length === 0 || isForecastClosed}
                className="btn btn-secondary float-start"
                type="button"
                onClick={handleDiscardAll}
              >
                Discard All Brand Family Projections
              </button>
            </div>
            <div className="col-6">
              <button
                className={cx('btn float-end', isDirty ? 'btn-primary' : 'btn-secondary')}
                type="button"
                onClick={handleSave}
                disabled={!isDirty}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandFamilyProjectionsTable;
